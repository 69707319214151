const envVars = process.env;

const Config = {
  nodeEnv: envVars.REACT_APP_NODE_ENV,
  uri: `${envVars.REACT_APP_APOLLO_GRAPHQL_URI}/graphql`,
  sUri: `${envVars.REACT_APP_APOLLO_SUBSCRIPTION_URI}/graphql`,
  okta: {
    issuer: envVars.REACT_APP_OKTA_ISSUER,
    redirect_uri: `${window.location.origin}/implicit/callback`,
    client_id: envVars.REACT_APP_CLIENT_ID,
  },
  tagManagerConfigs: {
    gtm_id: envVars.REACT_APP_GTM_ID,
    gtm_auth: envVars.REACT_APP_GTM_AUTH_ID,
    gtm_preview: envVars.REACT_APP_GTM_PREVIEW_ID,
    event: envVars.REACT_APP_GTM_EVENT_NAME,
  },
  cmsCollectionName: 'globalshells',
  gaTrackingId: envVars.REACT_APP_TRACKING_ID,
  instrumentationKey: envVars.REACT_APP_INSTRUMENTATION_KEY,
  lmsURL: envVars.REACT_APP_LMS_URL,
  gotoURL: envVars.REACT_APP_GOTO_URL,
  releaseNotesURL: envVars.REACT_APP_RELEASE_NOTES_URL,
  responsibleDisclosureURL: envVars.REACT_APP_RESPONSIBLE_DISCLOSURE_URL,
  jiraCollectorUrl1: `${process.env.REACT_APP_JIRA_COLLECTOR_URL}/s/d41d8cd98f00b204e9800998ecf8427e-CDN/-bmmtey/813002/6411e0087192541a09d88223fb51a6a0/2.2.4.7/_/download/batch/com.atlassian.plugins.jquery:jquery/com.atlassian.plugins.jquery:jquery.js?collectorId=4dd2725c`,
  jiraCollectorUrl2: `${process.env.REACT_APP_JIRA_COLLECTOR_URL}/s/fa581b6d058abdbb7ac53a7e609ec5c1-T/-bmmtey/813002/6411e0087192541a09d88223fb51a6a0/4.0.3/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-UK&collectorId=4dd2725c`,
  externalApps: (envVars.REACT_APP_EXTERNAL_APPS === undefined || envVars.REACT_APP_EXTERNAL_APPS === '') ? ['MKP']
    : envVars.REACT_APP_EXTERNAL_APPS.split(',').map((appCode) => appCode.trim()),
  nonIframeApps: (envVars.REACT_APP_NON_IFRAME_APPS === undefined || envVars.REACT_APP_NON_IFRAME_APPS === '') ? ['MKP']
    : envVars.REACT_APP_NON_IFRAME_APPS.split(',').map((appCode) => appCode.trim()),
  thpUrl: envVars.REACT_APP_THP_URL,
  umUrl: envVars.REACT_APP_UM_GRAPHQL_URL,
  // HELP_CENTER_LINK=https://globalappsportal.sharepoint.com/sites/LD_MSL/medialearn/SitePages/Dentsu-Connect-Learning-Center.aspx
  helpCenterLink: 'https://globalappsportal.sharepoint.com/sites/LD_MSL/medialearn/SitePages/Dentsu-Connect-Learning-Center.aspx',
  cspDomains: envVars.REACT_APP_CSP_DOMAINS,
};

const DataRefinaryConfigs = {
  conURL: envVars.REACT_APP_CON_URL,
  drConURL: envVars.REACT_APP_DR_CON_URL,
  drCompURL: envVars.REACT_APP_DR_COMP_URL,
  mapURL: envVars.REACT_APP_MAP_URL,
  detURL: envVars.REACT_APP_DET_URL,
  advURL: envVars.REACT_APP_ADV_URL,
  trnURL: envVars.REACT_APP_TRN_URL,
  scmURL: envVars.REACT_APP_SCM_URL,
  grcpURL: envVars.REACT_APP_GRCP_URL,
};

export default {
  ...Config,
  ...DataRefinaryConfigs,
};
